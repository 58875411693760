<template>
  <div id="divFeedDetailWrapper" class="content-layout-fixer">
    <div>
      <iframe
        id="iframe"
        style="max-width: 1024px;width: calc(100% - 20px);height: 573px;"
        :src="playerIframeUrl"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import StaticContent from '@/services/Api/staticContents';
import { mapGetters } from 'vuex';

export default {
  name: 'Player',
  data() {
    return {
      playerIframeUrl: '',
    };
  },
  mounted() {
    // let isClicked = false;
    // var eventListener = window.addEventListener('blur', function() {
    //   if (!isClicked && document.activeElement === document.getElementById('iframe')) {
    //     isClicked = true;
    //     console.log('clicked');
    //     setTimeout(function() {
    //       window.focus();
    //     }, 0);
    //   }
    //   window.removeEventListener('blur', eventListener);
    // });
    this.getPlayerIframeUrl();
  },
  computed: {
    ...mapGetters('auth', ['getUserDetails']),
  },
  methods: {
    getPlayerIframeUrl() {
      StaticContent.getStaticContent('playerIframeUrl').then(res => {
        if (res.data.Data && res.data.Data.content) {
          let url = res.data.Data.content;
          setTimeout(() => {
            this.playerIframeUrl = `${url}?customerCode=${this.getUserDetails?.customer_code}`;
          }, 1000);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
iframe {
  margin-left: 20px;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    height: 53.8vw !important;
  }
}
</style>
